<template>
  <div class="header">
    <div class="headerup">
      <div class="headerupleft">
        <svg class="icon" aria-hidden="true"><use href="#icon-back"></use></svg>
        Back to all forms
      </div>
      <div class="headerupcenter">
        Lead form
        <svg class="icon" aria-hidden="true">
          <use href="#icon-editName"></use>
        </svg>
      </div>
      <div class="headerupright">
        Auto-saved with
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ mydefault }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="unpublished changes"
              >unpublished changes</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <button>update</button>
      </div>
    </div>
    <div class="headerdown">
      <div class="headerdownleft">
        <el-select
          v-model="formpreview"
          placeholder="请选择"
          size="mini"
          style="width: 128px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="headerdowncenter">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Form" name="first"> </el-tab-pane>
          <el-tab-pane label="Follow-up" name="second"></el-tab-pane>
          <el-tab-pane label="Options" name="third"></el-tab-pane>
          <el-tab-pane label="Style & preview" name="fourth"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="headerdownright">
        <button>Clone</button>
        <el-select
          v-model="actions"
          placeholder="请选择"
          size="mini"
          style="width: 100px"
        >
          <el-option
            v-for="item in actionsoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <preview v-if="preview_" />
    <optionform v-if="opt" />
  </div>
</template>

<script>
import preview from "@/views/marketForm/createFrom/Previews/preview.vue";
import optionform from "@/views/marketForm/createFrom/Option/index.vue";

export default {
  components: {
    preview,
    optionform,
  },
  data() {
    return {
      preview_: false,
      opt: false,
      activeName: "first",
      actions: "Actions",
      mydefault: " ",
      formpreview: "Learn more",
      options: [
        {
          value: "选项1",
          label: "Mirror",
        },
        {
          value: "选项2",
          label: "This is it",
        },
      ],
      actionsoptions: [
        {
          value: "选项1",
          label: "Mirror",
        },
      ],
    };
  },
  methods: {
    handleCommand(command) {
      this.mydefault = command;
    },
    handleClick(tab) {
      if (tab.index === "3") {
        this.preview_ = true;
        this.opt = false;
      } else if (tab.index === "2") {
        this.preview_ = false;
        this.opt = true;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.icon {
  display: inline-block;
}
preview {
  margin-top: 0px;
}
::v-deep .el-input__inner {
  height: 30px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.header {
  width: 99%;
  height: 121px;
  background-color: azure;
  margin: 10px auto;
  .headerup {
    height: 60px;
    background: #f5f5f5;
    border: 1px solid #dddbda;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 0 20px;
    .headerupleft {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      img {
        width: 15.6px;
        height: 15.6px;
        margin-top: -3px;
        cursor: pointer;
      }
    }
    .headerupcenter {
      width: 30%;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      font-size: 16px;
      color: #080707;
      letter-spacing: 0;
      img {
        width: 15.1px;
        height: 15.2px;
        cursor: pointer;
      }
    }
    .headerupright {
      width: 30%;
      text-align: right;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      button {
        width: 65px;
        height: 30px;
        background: #2D6CFC;
        border-radius: 3px;
        outline: none;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        margin-left: 20px;
      }
    }
  }
  .headerdown {
    height: 61px;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-top: none;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 0 20px;
    .headerdowncenter {
      ::v-deep .el-tabs__nav-scroll {
        margin-top: 4px;
      }
      ::v-deep .is-top {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #080707;
        letter-spacing: 0;
        background-color: #fff;
      }
      ::v-deep .is-active {
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 16px;
        color: #080707;
        letter-spacing: 0;
      }
      ::v-deep .el-tabs__active-bar {
        background-color: #2D6CFC;
      }
    }
    .headerdownright {
      button {
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>